document.addEventListener("DOMContentLoaded", function(event) {

    const sAboutMeSections = document.querySelectorAll('.s-about-me');

    if (sAboutMeSections.length > 0) {

        sAboutMeSections.forEach(element => {

            const sAboutMeExpandBtn = element.querySelector('.s-about-me__expand-btn');

            if (sAboutMeExpandBtn != null) {
                sAboutMeExpandBtn.addEventListener('click', (e) => {

                    e.preventDefault();

                    const closestBtn = e.currentTarget.closest('.s-about-me');

                    closestBtn.classList.toggle('s-about-me--show-expanded-text');

                    if (e.currentTarget.innerHTML === 'Rozwiń') {
                        e.currentTarget.innerHTML = 'Zwiń';
                    } else {
                        e.currentTarget.innerHTML = 'Rozwiń';
                    }

                });
            }
        });

    }

});